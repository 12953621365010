/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-elastic-carousel';
import { useI18next } from 'gatsby-plugin-react-i18next';
import axios from 'axios';
import { Link } from 'gatsby';

// UI lib comoponents
import { Container } from 'react-grid-system';

// Local UI components
import SubTitle from '../../../shared/UIKit/SubTitle';

// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
import { convertDigitIn } from '../../../shared/Helpers';
// Style
import './index.scss';
// APIS
import { API_NEWS, API_UPLOADS } from '../../../shared/APIs/index';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function NewsCarousel() {
  /* ******************************** CONSTANTS ******************************* */
  // Carousel Props
  const CarouselSettings = {
    showArrows: false,
    pagination: false,
    enableAutoPlay: true,
    autoPlaySpeed: 5000,
    breakPoints: [
      { Width: 500, itemsToShow: 1, itemPadding: [0, 10] },
      { width: 850, itemsToShow: 3, itemPadding: [0, 20] },
    ],
  };
  /* ************************ ESSENTIAL LOCAL VARIABLES *********************** */

  /* ********************************** HOOKS ********************************* */

  // localisation
  const { t } = useTranslation();
  const { subTitle } = t('NewsDetail', { returnObjects: true });
  const { language } = useI18next();
  // State
  const [newsList, setNewsList] = useState();
  /* --------------------------------- HELPERS -------------------------------- */
  const pathLanguage = language === 'fr' ? '' : '/en';
  // get News List :
  async function getNews() {
    const { data } = await axios(API_NEWS(language));
    setNewsList(data);
  }
  // Effect
  useEffect(() => {
    getNews();
  }, []);

  /* ***************************** RENDER HELPERS ***************************** */
  const NewsMap = newsList?.data?.map((newItem) => {
    const {
      id,
      attributes: { title, date, image },
    } = newItem;
    return (
      <Link
        className="news-section-item-container"
        key={id}
        to={`${pathLanguage}/news/${newItem.id}`}
        state={{ newItem }}
      >
        <div className="news-item__img">
          <img src={API_UPLOADS(image?.data[0]?.attributes.url)} alt="news" />
        </div>
        <p className="news-item__date">{convertDigitIn(date)}</p>
        <h3 className="news-item__text">{title}</h3>
      </Link>
    );
  });

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="news-carousel" fluid>
      <SubTitle>{subTitle}</SubTitle>
      <Carousel {...CarouselSettings}>{NewsMap}</Carousel>
    </Container>
  );
}

NewsCarousel.propTypes = {};

NewsCarousel.defaultProps = {};

export default NewsCarousel;
