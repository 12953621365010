/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import PropTypes, { object } from 'prop-types';
import { useI18next } from 'gatsby-plugin-react-i18next';
import axios from 'axios';

// Lib UI components
import { Container, Row, Col } from 'react-grid-system';

// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import TitlePage from '../page-components/News/TitlePage';
import DetailsSection from '../page-components/NewsDetail/DetailsSection/index';
import NewsCarousel from '../page-components/NewsDetail/NewsCarousel';

import { API_NEWS_DETAIL } from '../shared/APIs';
// Style
import '../page-styles/NewsDetail.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function NewsDetail({ pageContext: { id } }) {
  /* ********************************** HOOKS ********************************* */
  const [newsDetail, setNewsDetail] = useState();
  const { language } = useI18next();

  /* --------------------------------- HELPERS -------------------------------- */
  async function getNewsDetail() {
    const { data } = await axios(API_NEWS_DETAIL(language, id));
    setNewsDetail(data.data.attributes);
  }

  useEffect(() => {
    getNewsDetail();
  }, []);

  /* ***************************** RENDER HELPERS ***************************** */
  function GetTiTlePage() {
    const title = newsDetail?.title ?? '';
    return (
      <Row justify="center" align="center" style={{ marginTop: '50px' }}>
        <Col lg={7}>
          <TitlePage NamePage={title} />
        </Col>
      </Row>
    );
  }
  /* ******************************** RENDERING ******************************* */
  if (newsDetail === undefined) {
    return <Container className="news-detail" />;
  }

  return (
    <PageLayout>
      <Seo title="News" />
      <Container className="News" fluid>
        <Container className="news-detail">
          <GetTiTlePage />
          <DetailsSection
            description={newsDetail?.description}
            image={newsDetail?.image}
            date={newsDetail?.date}
          />
          <NewsCarousel />
        </Container>
      </Container>
    </PageLayout>
  );
}
NewsDetail.propTypes = {
  pageContext: PropTypes.objectOf(object.isRequired).isRequired,
  id: PropTypes.number.isRequired,
};
NewsDetail.defaultProps = {};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["NewsDetail", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default NewsDetail;
