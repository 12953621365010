/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import PropTypes, { object } from 'prop-types';
// UI lib comoponents
import { Container, Row, Col } from 'react-grid-system';
// Style
import './index.scss';
// Helpers and utils
import { convertDigitIn } from '../../../shared/Helpers';
// APIs
import { API_UPLOADS } from '../../../shared/APIs/index';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function DetailsSection({ image, description, date }) {
  /* ******************************** CONSTANTS ******************************* */
  // date format to date string
  const dateFormat = convertDigitIn(date);
  // destruct image URL
  const { data } = image;
  const {
    attributes: { url },
  } = data[0];

  /* ***************************** RENDER HELPERS ***************************** */
  // New Date
  function GetDate() {
    return (
      <Row align="center" justify="center">
        <h5>{dateFormat}</h5>
      </Row>
    );
  }
  // New Image
  function GetImageNews() {
    return (
      <Row justify="center" align="center" className="image-container">
        <img src={API_UPLOADS(url)} alt="News" />
      </Row>
    );
  }
  // New Description
  function GetDescNews() {
    return (
      <Row align="center" justify="center" className="desc-container">
        <p>{description}</p>
      </Row>
    );
  }
  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="details-section" fluid>
      <Row justify="center" align="center">
        <Col lg={8} justify="between">
          <GetDate />
          <GetImageNews />
          <GetDescNews />
        </Col>
      </Row>
    </Container>
  );
}

DetailsSection.propTypes = {
  image: PropTypes.objectOf(object.isRequired).isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

DetailsSection.defaultProps = {};

export default DetailsSection;
